<template>
  <v-container>
    <chart
      :options="options"
    ></chart>
  </v-container>

</template>

<script>
import Echarts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/dataZoomInside'
import 'echarts/lib/component/legend'

const moment = require('moment')
const axios = require('axios').default
axios.defaults.timeout = 5000
const {
  VUE_APP_API_URL
} = process.env

export default {
  name: 'StoreHistoryGraphv2',
  props: ['storeId'],
  components: { chart: Echarts },
  data () {
    return {
      dataSource: {
        data: null
      }
    }
  },
  computed: {
    options: function () {
      return {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Picks (per hour)', 'Collections (per hour)', 'Consignments Created', 'Time Offline', 'Pick Queue', 'Average Pick Time', 'Customer On Way', 'Click & Parks', 'Arrival Time Set']
        },
        toolbox: {
          feature: {
            restore: {
              title: 'Reset Zoom'
            },
            saveAsImage: {
              title: 'Download Image'
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dates
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [{
          type: 'inside',
          start: 70,
          end: 100
        }, {
          start: 0,
          end: 10,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }],
        series: [
          {
            name: 'Picks (per hour)',
            type: 'bar',
            data: this.pickData
          },
          {
            name: 'Collections (per hour)',
            type: 'bar',
            data: this.collectionData
          },
          {
            name: 'Time Offline',
            type: 'line',
            data: this.timeOfflineData
          },
          {
            name: 'Pick Queue',
            type: 'line',
            data: this.pickQueueData
          },
          {
            name: 'Arrival Time Set',
            type: 'line',
            data: this.arrivalTimeSetData
          },
          {
            name: 'Click & Parks',
            type: 'bar',
            data: this.clickAndParkRequestedData
          },
          {
            name: 'Customer On Way',
            type: 'line',
            data: this.customerArrivedData
          },
          {
            name: 'Consignments Created',
            type: 'bar',
            data: this.consignmentCreationData
          },
          {
            name: 'Average Pick Time',
            type: 'line',
            data: this.pickTimeData
          }
        ]
      }
    },
    sortedData: function () {
      if (this.dataSource && this.dataSource.data !== null) {
        const data = JSON.parse(JSON.stringify(this.dataSource.data))
        return data.sort((a, b) => a.statsDate - b.statsDate)
      } else {
        return []
      }
    },
    dates: function () {
      return this.extract(this.sortedData, item => moment.unix(item.statsDate).format('YYYY/MM/DD hha'))
    },
    pickData: function () {
      const picks = this.extract(this.sortedData, item => item.picks)
      return this.convertToDiff(picks)
    },
    collectionData: function () {
      return this.convertToDiff(this.extract(this.sortedData, item => item.collections))
    },
    timeOfflineData: function () {
      return this.extract(this.sortedData, item => item.timeOffline)
    },
    pickQueueData: function () {
      return this.extract(this.sortedData, item => item.pickQueue)
    },
    pickTimeData: function () {
      return this.extract(this.sortedData, item => item.stats ? item.stats.pickTime : {})
    },
    arrivalTimeSetData: function () {
      return this.convertToDiff(this.extract(this.sortedData, item => item.stats ? item.stats.arrivalTimeSet : {}))
    },
    clickAndParkRequestedData: function () {
      return this.convertToDiff(this.extract(this.sortedData, item => item.stats ? item.stats.clickAndParkRequested : {}))
    },
    customerArrivedData: function () {
      return this.convertToDiff(this.extract(this.sortedData, item => item.stats ? item.stats.customerArrived : {}))
    },
    consignmentCreationData: function () {
      return this.convertToDiff(this.extract(this.sortedData, item => item.stats ? item.stats.consignmentCreation : {}))
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    extract: function (data, mapFunction) {
      return this.sortedData.map(mapFunction)
    },
    convertToDiff: function (data) {
      let previousCount = 0
      const trueCount = []
      data.forEach(item => {
        const currentCount = item || 0
        if (currentCount < previousCount) {
          // day change
          previousCount = 0
        }
        trueCount.push(currentCount - previousCount)
        previousCount = currentCount
      })
      return trueCount
    },
    fetchData: async function () {
      const startDate = moment.tz('Europe/London').add(-1, 'month')
      const endDate = moment(startDate).add(1, 'month').add(1, 'day')

      const token = await this.$auth.getTokenSilently()
      axios.get(`${VUE_APP_API_URL}/cats/admin/${this.storeId}/stats`, {
        headers: {
          Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        },
        params: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD')
        }
      })
        .then(response => response.data)
        .then(data => {
          this.dataSource.data = data
        })
    }
  }
}
</script>

<style>
.echarts {
  width: 100%;
}
</style>
